import { currencyFormatter } from "../utils/currencyFormatter";
import { useDispatch } from "react-redux";
import { addtoCart } from "../features/products/cartSlice";
import { Link } from "react-router-dom";
const Card = ({ product }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCartHandler = (product) => {
    dispatch(addtoCart({ ...product }));
    // navigate("/addtocart");
  };
  return (
    <div className=" w-full  shadow-md rounded-md overflow-hidden">
      <Link to={`/productdetails/${product.id}`}>
        <div className=" img  h-[11rem] flex  justify-center items-center  overflow-hidden">
          <img
            src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
            alt="mehedi product"
            className="w-full h-full object-cover"
          />
        </div>
      </Link>

      <div className="p-2 flex flex-col">
        <Link
          to={`/productdetails/${product.id}`}
          className="text-[#b47a06] font-semibold "
        >
          {currencyFormatter(+product.unit_price)}
        </Link>
        <Link to={`/productdetails/${product.id}`}>
          <h3 className="text-[1rem] truncate  font-normal text-[#212121] mb-2 overflow-hidden">
            {product?.name}
          </h3>
        </Link>

        <div className="flex flex-col text-center  gap-2 justify-center items-center text-gray-500">
          <button
            onClick={() => addToCartHandler(product)}
            className="bg-[#6c350b]  text-slate-50 hover:bg-[#572907] duration-300 w-full py-1 rounded-full"
          >
            Add to Cart
          </button>
          <Link
            to={`/productdetails/${product.id}`}
            className="border text-center  border-[#6c350b] hover:text-slate-50 hover:bg-[#6c350b] duration-300 w-full  rounded-full py-[2px] "
          >
            View Details
          </Link>
          {/* to={"/productdetails/"} */}
        </div>
      </div>
    </div>
  );
};

export default Card;
