import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ProductImageCarasol = ({ singleProduct, imageThamb }) => {
  return (
    <div className="text-white text-[20px] w-auto md:w-[30rem]  lg:w-[40rem] object-cover">
      <Carousel
        showIndicators={false}
        showStatus={false}
        thumbWidth={60}
        infiniteLoop={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        emulateTouch={false}
        className="productCarousel"
      >
        <img
          src={singleProduct}
          alt=""
          className="w-auto h-[30rem] object-cover "
        />
        {imageThamb?.map((img) => (
          <div key={img?.id} className="h-[25rem]">
            <img
              src={`${process.env.REACT_APP_URL}/uploads/product/${img?.icon}`}
              alt="thamb pic"
              className="w-full h-full object-fill"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductImageCarasol;
