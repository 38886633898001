import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AddToCartCom from "../components/AddToCartCom";
import CartButton from "../components/CardButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileNav from "../components/MobileNav";

const HomeLayout = () => {
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  // const [loading, setLoading] = useState(false);

  const [state, setState] = useState(false);

  const handleAddToCart = () => {
    setState(!state);
  };
  return (
    <>
      <div className="min-h-screen relative ">
        <Navbar />
        <ToastContainer />
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>

        <button
          onClick={handleAddToCart}
          className="fixed top-[45%] right-0 z-[9999]"
        >
          <CartButton data={data} cartTotalAmount={cartTotalAmount} />
        </button>
        {state && <AddToCartCom state={state} setState={setState} />}
        <div className="fixed z-[99999] bottom-0  md:hidden block w-full ">
          <MobileNav />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HomeLayout;
